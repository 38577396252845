import React, {useContext, useEffect, useState} from 'react';
import {Button, Card, Col, Jumbotron, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {UtilitiesContext} from "../../providers/utilitiesProvider";
import {AWSCONSTANTS, DATA_VALUES, FILTER_TYPES, VALID_VALUES} from "../../utils/constants";
import {PreloaderComponent} from "../../components/preloaderComponent";
import {ResponsiveContainerComponent} from "../../components/responsiveContainerComponent";
import {DFCForm} from "../../components/dfc/dfcForm";
import {AuthorizationContext} from "../../providers/authorizationProvider";
import {InstitutionContactsForm} from "../../view_forms/institutions/institutionContactsForm";
import {DFCList} from "../../components/dfc/dfcList";
import {InstitutionOthersForm} from "../../view_forms/institutions/institutionOthersForm";
import {InstitutionSummaryForm} from "../../view_forms/institutions/institutionSummaryForm";
import {InstitutionAdditionalInformationForm} from "../../view_forms/institutions/institutionAdditionalInformationForm";
import {DFCFilter, joinerOptions} from "../../components/dfc/dfcFilter";
import {ProgramsFilteredView} from "../programs/programsView";

export const InstitutionView = () => {
    const params = useParams();
    const {GetDataFromServer, setDynamicViewTitleHTML, SaveDataToServer} = useContext(UtilitiesContext);
    const {GetUserAttribute} = useContext(AuthorizationContext)
    const institutionId = params.id;
    const [institutionData, setInstitutionData] = useState(DATA_VALUES.LOADING);
    const [terms, setTerms] = useState(DATA_VALUES.LOADING);


    const LoadInstitution = () => {
        if (institutionId) {
            GetDataFromServer(`/institution/index/${institutionId}?include=Photos`, setInstitutionData);
            GetDataFromServer(`/term/index`, setTerms);

        }
    }
    useEffect(() => {
        LoadInstitution();
    }, []);

    useEffect(()=>{
        if(institutionData && institutionData !== DATA_VALUES.LOADING)
            setDynamicViewTitleHTML(`ID ${institutionData.displayIndex}: ${institutionData.name}`)
    },[institutionData, setDynamicViewTitleHTML]);


    const SwitchLive = (goLive) => {
        const _institution = {
            id: institutionId,
            goLive: goLive
        }
        SaveDataToServer(`/institution/index`, "patch", _institution, null, false, LoadInstitution);
    }

    const IsAdmin = GetUserAttribute(AWSCONSTANTS.ATTRIBUTENAME_PROFILETYPE) === VALID_VALUES.PROFILETYPES.ADMIN;
    const IsEditingAllowed =IsAdmin && !institutionData?.goLive;

    if(!institutionData)
        return DATA_VALUES.NODATA_HTML;


    if (institutionData === DATA_VALUES.FAILED)
    {
        setDynamicViewTitleHTML("Error");
        return DATA_VALUES.NODATA_HTML
    }

    const filterCategories =
        [
            {
                // need identifier and prefix expression
                name: "Programs",
                filterType: FILTER_TYPES.SEARCH,
                expression: "data.Name.Trim().ToLower().Contains({$value})"
            },
            {
                name: "Program Type",
                filterType: FILTER_TYPES.SELECT,
                options: ["Bachelors", "Certificate", "Diploma", "Masters", "Others"].map(x=>{return {label: x, value:x}}),
                expression: "data.EducationLevel=={$value}"
            },
            {
                // need identifier and prefix expression
                name: "Terms",
                filterType: FILTER_TYPES.SELECT,
                options: Array.isArray(terms) && terms.map(x => {return {label: x.name, value:x.name}}).concat({label: "Any", value: " "}),
                expression: `data.ProgramTerms.Any(pt=>pt.Term.Name.Contains({$value})${joinerOptions.and}pt.Open.Value)`
            }
        ]

    if (institutionData === DATA_VALUES.LOADING)
        return <PreloaderComponent/>

    return (
        <>
            <Jumbotron className={"institution-jumbotron position-relative h-auto place-content-center text-light font-weight-bolder"} fluid >
                {institutionData.photos &&
                    <div className={"institution-jumbotron-image-overlay h-100 w-100 position-absolute top-0  filter-brightness-50"} style={{backgroundImage: `url('${institutionData.photos[0]?.link}')`}}>

                    </div>
                }
                <ResponsiveContainerComponent>
                    {
                        IsAdmin && <Row>
                            <Col xs={12}>
                                <Button variant={institutionData.goLive? "warning":"danger"} className={"z-index-1 w-100 font-weight-bold"}
                                        onClick={()=>SwitchLive(!institutionData.goLive)}>
                                    {institutionData.goLive? "Go Maintenance Mode":"Go Live"}
                                </Button>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Card bg={"dark-70"} className={"mx-3"}>
                            <Card.Body>
                                {institutionData.details}
                                <hr />
                                Colloquial Name: {institutionData.shortName}
                            </Card.Body>
                        </Card>
                    </Row>
                    {
                        !IsAdmin && <Row className={"mt-1"}>
                            <Col xs={12}>
                                <a  href={"#programs-tab"} className={"z-index-1 w-100 font-weight-bold btn btn-success text-light"}>APPLY</a>
                            </Col>
                        </Row>
                    }
                </ResponsiveContainerComponent>
            </Jumbotron>
            <ResponsiveContainerComponent>
                <Row>
                    <Col className={"order-1 order-md-0"} xs={12} md={8}>

                        <div className={"pt-2"}>
                            <DFCFilter
                                title={"Search Programs"}
                                getDataUrl={"/program/index?include=Institution"}
                                defaultQueries={{'data.Institution.Name.ToLower()=={$value}':{expression:`data.Institution.Name.ToLower()=="${institutionData.name?.toLowerCase()}"`, joiner: joinerOptions.and}}}
                                categories={filterCategories}
                                content={ProgramsFilteredView}
                                takePerPage={20}
                                filterOptionSize={12}
                            />
                        </div>



                    </Col>

                    <Col className={"order-0 order-md-1"} xs={12} md={4}>

                        <DFCList title={"Why Choose Us?"}
                                 instructions={"Special features and highlights that " +
                                     "enthusiast students. There maybe attachments for details as well."}
                                 getListDataUrl={`/institution/list/${institutionData.id}/AdditionalInformation`}
                                 addDataUrl={`/institution/list/${institutionData.id}/AdditionalInformation`}
                                 editDataUrl={`/institution/list/${institutionData.id}/AdditionalInformation`}
                                 deleteDataUrl={`/institution/list/${institutionData.id}/AdditionalInformation`}
                                 headerContent={(hProps) => <h6>{_.get(hProps.data, "name")}</h6>}
                                 formContent={InstitutionAdditionalInformationForm}
                                 isEditable={IsEditingAllowed}
                        />
                        <DFCForm title={"Contacts"}  modalSize={"sm"}
                            formContent={InstitutionContactsForm} data={institutionData}
                                 setData={setInstitutionData} LoadData={LoadInstitution} isEditable={IsEditingAllowed}
                                 saveDataUrl={`/institution/index?Validator=InstitutionContactsValidator`} />

                        <DFCForm title={""}  isEditable={IsEditingAllowed}
                                 formContent={InstitutionSummaryForm} data={institutionData} setData={setInstitutionData} LoadData={LoadInstitution}
                                 saveDataUrl={`/institution/index?Validator=InstitutionContactsValidator`}
                        />

                        <DFCForm title={"Ranking"}  modalSize={"sm"}
                                 formContent={InstitutionOthersForm} data={institutionData} setData={setInstitutionData} LoadData={LoadInstitution} isEditable={IsEditingAllowed}
                                 saveDataUrl={`/institution/index?Validator=InstitutionOthersValidator`}/>
                    </Col>
                </Row>
            </ResponsiveContainerComponent>

        </>
    )
}