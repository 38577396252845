import React, {useCallback, useContext, useEffect, useState} from 'react';
import Tippy from '@tippyjs/react';
import {UtilitiesContext} from '../../providers/utilitiesProvider';
import toast from 'react-hot-toast';
import {DATA_VALUES, TOAST_SETTINGS} from '../../utils/constants';
import {PreloaderComponent} from '../preloaderComponent';
import {Accordion, Button, Card, Col, Dropdown, Image, ListGroup, Modal, Row} from "react-bootstrap";


export const DFCForm = (props) => {
    const [isEditing, setIsEditing] = useState(false);
    const [data, setData] = useState(DATA_VALUES.NULL);
    const {GetDataFromServer, SaveDataToServerWithoutConfirmation, SaveDataToServer} = useContext(UtilitiesContext);


    const LoadData = useCallback(() => {
        //grab data again
        if (props.getDataUrl)
            GetDataFromServer(props.getDataUrl, setData, false);

        if (props.LoadData)
            props.LoadData();

    }, [props.getDataUrl, props.LoadData]);

    const CompleteEditing = () => {
        setIsEditing(props.isEditing ?? false);
        LoadData();
        toast.dismiss(TOAST_SETTINGS.IDS.UNSAVEDCHANGES);
        if (props.onComplete)
            props.onComplete();
    };

    const SaveData = () => {
        const saveFunction = props.editWithModal ? SaveDataToServerWithoutConfirmation : SaveDataToServer;
        saveFunction(props.editDataUrl ? props.editDataUrl : props.saveDataUrl, props.saveHttpMethod ?? "put",
            data, null, false, CompleteEditing);
    }

    const DeleteData = (item) => {
        SaveDataToServer(props.deleteDataUrl, 'delete', item, null, false, LoadData);
    };


    useEffect(() => {
        setIsEditing(props.isEditing ?? isEditing ?? false);

        if (!props.getDataUrl)
            setData(props.data);
        else
            LoadData();

    }, [props.data, props.getDataUrl, props.isEditing]);


    if (data === DATA_VALUES.LOADING)
        return (<PreloaderComponent small/>)


    return (
        <>
            {/*Editing Modal*/

                props.editWithModal &&
                <Modal
                    show={isEditing && props.editWithModal} onHide={CompleteEditing}
                    size={props.modalSize ?? "lg"}
                    backdrop="static"
                    centered
                >
                    <Modal.Header closeButton className={"bg-primary text-light"}>
                        <Modal.Title className={"text-center"}>
                            {props.title}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className={"row"}>
                        <props.formContent data={data} setData={setData} formData={props.formData}
                                           isEditing={true} col={12} isEditable={props.isEditable}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant={"success"} className={"w-100"} onClick={SaveData}>Save</Button>
                    </Modal.Footer>
                </Modal>

            }

            <Card className={"" + props.className + (props.inList ? " border-1 rounded-0" : " my-2")}>
                {props.inList ?

                    <Card.Header className={"p-2 dfc-list-header hover-bg-gray " + props.headerClassName}>

                            <Row>

                                <Col xs={(props.isEditable && (props.deleteDataUrl || props.editDataUrl)) ? 9 : 12}
                                     className={"align-self-center "}>
                                    <Accordion.Toggle as={"div"} eventKey={props.index.toString()}
                                                      className={(props.hideBody ? "" : "cursor-pointer") + "  w-100 ml-2 cursor-pointer"}>
                                    <Row className={"px-2"}>
                                        <props.headerContent data={data}/>
                                    </Row>

                                    {!props.hideBody &&
                                        <div className={"text-extra-small text-muted text-center"}><i
                                            className={"bi bi-caret-down-fill text-small d-inline"}></i>view details
                                        </div>
                                    }
                                    </Accordion.Toggle>
                                </Col>
                                <Col className={"text-right"}>

                                    {props.isEditable && props.editDataUrl && !isEditing
                                        && <Tippy content="Edit" showOnCreate={true}>
                                            <Button variant={"outline-secondary"} size={"sm"} onClick={() => {
                                                setIsEditing(true);
                                            }}>
                                                <i className="bi bi-pencil-fill m-1"></i> {/*EDIT*/}
                                            </Button>
                                        </Tippy>
                                    }
                                    {
                                        props.isEditable && props.deleteDataUrl &&
                                        <Tippy content={"Delete Record"}>
                                            <button className="d-inline btn btn-outline-danger btn-sm m-1"
                                                    onClick={() => DeleteData(data)}>
                                                <i className="bi bi-trash m-1"></i>
                                            </button>
                                        </Tippy>
                                    }
                                </Col>
                            </Row>

                        <Row className={"m-0 p-0 float-right"}>
                            <span className={"text-muted text-extra-small"}>
                                {props.listLength && <>{props.index} of {props.listLength}</>}
                            </span>
                        </Row>
                    </Card.Header>
                    :
                    <Card.Header className={"w-100 bg-primary-light text-light py-2 " + props.headerClassName}>
                        <Row>
                            <Col xs={(props.isEditable && (props.saveDataUrl || props.editDataUrl)) ? 9 : 12}>
                                <h5>{!props.inList && props.title}</h5>
                                {props.required &&
                                    <span className="text-danger text-large fw-bold">*</span>}
                                {props.instructions &&
                                    <Tippy content={props.instructions}>
                                        <i class="bi bi-info-square text-gray m-1"></i>
                                    </Tippy>}
                            </Col>
                            <Col className={"text-right"}>
                                {(props.isEditable && props.saveDataUrl && !isEditing)
                                    && <Tippy content="Edit" showOnCreate={true} placement={"bottom"}>
                                        <Button variant={"outline-light"} size={"sm"} onClick={() => {
                                            setIsEditing(true);
                                        }}>
                                            <i className="bi bi-pencil-fill m-1"></i> {/*EDIT*/}
                                        </Button>
                                    </Tippy>
                                }

                                {
                                    props.isEditable && props.deleteDataUrl &&
                                    <Tippy content={"Add a Record"}>
                                        <button className="d-inline btn btn-outline-danger btn-sm m-1"
                                                onClick={() => DeleteData(data)}>
                                            <i className="bi bi-trash m-1"></i>
                                        </button>
                                    </Tippy>
                                }
                            </Col>
                        </Row>

                    </Card.Header>
                }
                {
                    !props.hideBody && props.formContent &&
                    (
                        props.inList ?
                            <Accordion.Collapse eventKey={props.inList && props.index.toString()}>
                                <Card.Body className={"px-md-4 row"}>
                                    <props.formContent data={data} setData={setData} {...props}
                                                       formData={props.formData}/>

                                    {(!props.editWithModal && props.isEditing) &&
                                        <Row className={"w-100 mx-auto mt-3"}>
                                            <Col xs={12}>
                                                <Button className={"w-100"} variant={"success"}
                                                        onClick={SaveData}>Save</Button>
                                            </Col>
                                        </Row>
                                    }
                                </Card.Body>
                            </Accordion.Collapse> :
                            <Card.Body className={"px-3 row"}>
                                <props.formContent data={data} setData={setData} isEditable={props.isEditable}
                                                   isEditing={isEditing}
                                                   {...props} formData={props.formData}/>

                                {(!props.editWithModal && isEditing) &&
                                    <Row className={"w-100 mx-auto mt-3"}>
                                        <Col xs={12}>
                                            <Button className={"w-100"} variant={"success"}
                                                    onClick={SaveData}>Save</Button>
                                        </Col>
                                    </Row>
                                }
                            </Card.Body>
                    )
                }
            </Card>
        </>
    )
}